import { DateTime } from 'luxon';
import { addLocale, useLocale } from 'ttag';
import * as cookie from './cookie';

const LOCALE_COOKIE = 'grantme-www-locale';
const SWEDISH_LOCALE = 'sv';
const ENGLISH_LOCALE = 'en';

export function getLocale() {
  return cookie.get(LOCALE_COOKIE);
}

export const setLocale = async (locale: string) => {
  cookie.set(LOCALE_COOKIE, locale, DateTime.now().plus({ months: 12 }).toJSDate());
};

export function initLocale() {
  let locale = getLocale();
  if (!locale) {
    locale = SWEDISH_LOCALE;

    setLocale(locale);
  }

  if (locale !== SWEDISH_LOCALE) {
    (async () => {
      const translationsObj = await require(`../i18n/${locale}.po.json`);
      addLocale(locale, translationsObj);
      useLocale(locale);
    })();
  }
}
